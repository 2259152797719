export const POST_PLACEHOLDER_COLOR = 'color-4';
export const POST_TEXT_COLOR = 'color-5';
export const POST_ICONS_COLOR = 'color-5';

export enum CoverImageLayout {
  thumbnail = 'thumbnail',
  large = 'large',
  blank = 'blank',
}

export enum MediaLayout {
  grid = 'grid',
  masonry = 'masonry',
  collage = 'collage',
}

export type MediaImagesPerRow = 3 | 4 | 5 | 6;
