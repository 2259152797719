import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import cls from 'classnames';

import { Button } from 'wix-ui-tpa';
import type { ImageFocalPoint } from 'wix-ui-tpa';

import { Reposition as RepositionIcon } from 'common/components/Icons/Reposition';

import { st, classes } from './FocalPointSelect.st.css';

interface IFocalPointSelectProps {
  value: ImageFocalPoint;

  onActiveChange(active: boolean): void;
  onChange(value: ImageFocalPoint): void;
}

export function FocalPointSelect(props: IFocalPointSelectProps) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const [active, setIsActive] = React.useState(false);

  return (
    <div
      className={cls(classes.root, { [classes.active]: active })}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleTouchEnd}
      onMouseMove={handleMouseMove}
      onTouchMove={handleTouchMove}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        upgrade
        className={cls(classes.button)}
        prefixIcon={<RepositionIcon />}
      >
        {t('groups-web.reposition.drag-cta')}
      </Button>
    </div>
  );

  function handleMouseMove(event: React.MouseEvent) {
    if (!active) {
      return;
    }

    handleChange(event.movementX * -1, event.movementY * -1);
  }

  function handleTouchMove(event: React.TouchEvent) {
    const touch = event.touches.item(0);

    if (!active) {
      return;
    }

    handleChange(touch.pageX, touch.pageY);
  }

  function handleChange(x: number, y: number) {
    props.onChange({
      x: clamp(lerp(props.value.x, props.value.x + x, 0.3), 0, 100),
      y: clamp(lerp(props.value.y, props.value.y + y, 0.3), 0, 100),
    });
  }

  function handleTouchEnd() {
    setIsActive(false);
    props.onActiveChange(false);
  }

  function handleMouseUp() {
    setIsActive(false);
    props.onActiveChange(false);
  }

  function handleMouseLeave() {
    setIsActive(false);
    props.onActiveChange(false);
  }

  function handleMouseDown(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    setIsActive(true);
    props.onActiveChange(true);
  }

  function handleTouchStart(event: React.TouchEvent) {
    event.preventDefault();
    event.stopPropagation();

    setIsActive(true);
    props.onActiveChange(true);
  }
}

function clamp(value: number, min: number, max: number) {
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  }

  return value;
}

function lerp(value1: number, value2: number, amount: number) {
  amount = amount < 0 ? 0 : amount;
  amount = amount > 1 ? 1 : amount;

  return value1 + (value2 - value1) * amount;
}
