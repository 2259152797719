import React from 'react';
import { RadioButton, RadioButtonProps } from 'wix-ui-tpa';

import { st, classes } from './BlackAndWhiteRadioButton.st.css';

export const BlackAndWhiteRadioButton: React.FC<RadioButtonProps> = (props) => {
  return (
    <RadioButton {...props} className={st(classes.root, {}, props.className)} />
  );
};
