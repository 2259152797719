import React from 'react';
import {
  Text,
  TextTypography,
  Divider,
  CheckboxGroup,
  Checkbox,
} from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  NotificationChannel,
  NotificationType,
  NotificationSettings,
} from '@wix/ambassador-social-groups-v1-notification-settings/types';

import { BlackAndWhiteToggleSwitch } from 'common/components/BlackAndWhiteToggleSwitch/BlackAndWhiteToggleSwitch';
import { Block, BlockAlign, BlockFlow } from 'common/components/Block';

import { classes } from './ChannelSettings.st.css';

export const NotificationCaptions = {
  [NotificationChannel.WEB]: 'groups-web.notifications.website.caption',
  [NotificationChannel.MOBILE]: 'groups-web.notifications.mobile.caption',
  [NotificationChannel.EMAIL]: 'groups-web.notifications.email.caption',
};

export const NotificationTitles = {
  [NotificationChannel.EMAIL]: 'groups-web.notifications.email',
  [NotificationChannel.MOBILE]: 'groups-web.notifications.mobile',
  [NotificationChannel.WEB]: 'groups-web.notifications.website',
};

type HandledNotificationType = Exclude<
  NotificationType,
  NotificationType.ALL | NotificationType.WEEKLY_DIGEST
>;

const NotificationGroup: Record<
  HandledNotificationType,
  { name: string; label: string; caption: string }
> = {
  [NotificationType.POST]: {
    name: 'groups-web.notifications.posts',
    label: 'groups-web.notifications.posts',
    caption: 'groups-web.notifications.posts.caption',
  },
  [NotificationType.COMMENT]: {
    name: 'groups-web.notifications.comments',
    label: 'groups-web.notifications.comments',
    caption: 'groups-web.notifications.comments.caption',
  },
  [NotificationType.REACTION]: {
    name: 'groups-web.notifications.reactions',
    label: 'groups-web.notifications.reactions',
    caption: 'groups-web.notifications.reactions.caption',
  },
  [NotificationType.GROUP_UPDATES]: {
    name: 'groups-web.notifications.group_updates',
    label: 'groups-web.notifications.group_updates',
    caption: 'groups-web.notifications.group_updates.caption',
  },
  [NotificationType.MEMBERSHIP]: {
    name: 'groups-web.notifications.members',
    label: 'groups-web.notifications.members',
    caption: 'groups-web.notifications.members.caption',
  },
  [NotificationType.MEMBERS_JOIN]: {
    name: 'groups-web.notifications.members_join',
    label: 'groups-web.notifications.members_join',
    caption: 'groups-web.notifications.members_join.caption',
  },
};

interface ChannelSettingsProps {
  settings: NotificationSettings[];
  channel: NotificationChannel;
  onChange(updatedSettings: NotificationSettings[]): void;
}

export const ChannelSettings: React.FC<ChannelSettingsProps> = ({
  settings,
  channel,
  onChange,
}) => {
  const { t } = useTranslation();
  const title = t(`${NotificationTitles[channel]}.title`, {
    channel: t(NotificationTitles[channel]),
  });
  const caption = t(NotificationCaptions[channel]);

  const allOption = getOptionByType(NotificationType.ALL);
  if (!allOption) {
    return null;
  }

  function renderCheckbox(key: HandledNotificationType) {
    const keys = NotificationGroup[key];
    const option = getOptionByType(key);
    if (!option) {
      return null;
    }

    return (
      <Checkbox
        name={t(keys.label)}
        checked={!option.mute}
        onChange={() => mute(key)}
        label={
          <>
            {t(keys.name)}
            <p className={classes.checkboxCaption}>{t(keys.caption)}</p>
          </>
        }
        className={classes.checkbox}
      />
    );
  }

  return (
    <Block flow={BlockFlow.row} className={classes.root}>
      <Block
        align={BlockAlign.start}
        end={
          <BlackAndWhiteToggleSwitch
            checked={!allOption.mute}
            onChange={() => mute(NotificationType.ALL)}
            aria-label={title}
          />
        }
        className={classes.autoContent}
      >
        <Block flow={BlockFlow.row}>
          <Text
            typography={TextTypography.smallTitle}
            tagName="h3"
            className={classes.title}
          >
            {title}
          </Text>
          <Text
            typography={TextTypography.runningText}
            className={classes.bodyText}
          >
            {caption}
          </Text>
        </Block>
      </Block>
      <Divider className={classes.divider} />
      <CheckboxGroup disabled={allOption.mute}>
        {renderCheckbox(NotificationType.POST)}
        {renderCheckbox(NotificationType.COMMENT)}
        {channel !== NotificationChannel.EMAIL &&
          renderCheckbox(NotificationType.REACTION)}
        {renderCheckbox(NotificationType.MEMBERSHIP)}
        {renderCheckbox(NotificationType.GROUP_UPDATES)}
        {renderCheckbox(NotificationType.MEMBERS_JOIN)}
      </CheckboxGroup>
    </Block>
  );

  function mute(type: NotificationType) {
    if (type === NotificationType.ALL) {
      muteAll();
    } else {
      muteType(type);
    }
  }

  function muteAll() {
    const allTypeIndex = settings.findIndex(
      (s) => s.notificationType === NotificationType.ALL,
    );
    if (allTypeIndex === -1) {
      return;
    }

    const updatedSettings = settings.map((s) => {
      return {
        ...s,
        mute: !settings[allTypeIndex].mute,
      };
    });
    onChange(updatedSettings);
  }

  function muteType(type: NotificationType) {
    const updatedSettings = [...settings];
    const indexToUpdate = updatedSettings.findIndex(
      (s) => s.notificationType === type,
    );
    if (indexToUpdate === -1) {
      return;
    }

    updatedSettings[indexToUpdate] = {
      ...updatedSettings[indexToUpdate],
      mute: !updatedSettings[indexToUpdate].mute,
    };
    onChange(updatedSettings);
  }

  function getOptionByType(type: NotificationType) {
    return settings.find((s) => s.notificationType === type);
  }
};
