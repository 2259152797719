import React from 'react';
import { TextButtonPriority } from 'wix-ui-tpa';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import {
  groupMembershipQuestionsAddQuestionClickIntent,
  groupMembershipQuestionsQuestionActions,
} from '@wix/bi-logger-groups/v2';

import { StyledDivider } from 'common/components/Divider/Divider';
import { BlackAndWhiteTextButton } from 'common/components/BlackAndWhiteTextButton';

import { EditQuestion } from './EditQuestion';
import { IQuestion } from './types';

import { classes } from './GroupQuestions.st.css';

type EditQuestionsProps = {
  groupId: string;
  questions: IQuestion[];
  onAdd(): void;
  onRemove(index: number): void;
  onBlur(index: number): void;
  onChange(question: IQuestion, index: number): void;
};

export const EditQuestions: React.FC<EditQuestionsProps> = ({
  groupId,
  questions,
  onAdd,
  onRemove,
  onChange,
  onBlur,
}) => {
  const { t } = useTranslation();
  const bi = useBi();

  return (
    <>
      {questions.map((q, i) => {
        return (
          <>
            <EditQuestion
              key={q.id}
              index={i + 1}
              question={q}
              forceFocus={q.forceFocus}
              onRemove={() => handleRemoveQuestionClick(q.id!, i)}
              onChange={(question) => onChange(question, i)}
              onBlur={(question) => handleQuestionBlur(question, i)}
            />
            <StyledDivider />
          </>
        );
      })}
      <BlackAndWhiteTextButton
        priority={TextButtonPriority.primary}
        className={classes.addQuestion}
        onClick={handleAddQuestionClick}
      >
        + {t('groups-web.membership-questions.add')}
      </BlackAndWhiteTextButton>
    </>
  );

  function handleAddQuestionClick() {
    onAdd();

    bi.report(
      groupMembershipQuestionsAddQuestionClickIntent({
        groupId,
        origin: 'add_question_scr_plus_btn',
      }),
    );
  }

  function handleRemoveQuestionClick(id: string, index: number) {
    onRemove(index);

    bi.report(
      groupMembershipQuestionsQuestionActions({
        action: 'delete',
        groupId,
        origin: 'add_question_scr',
        questionId: id,
      }),
    );
  }

  function handleQuestionBlur(question: IQuestion, index: number) {
    onBlur(index);

    bi.report(
      groupMembershipQuestionsQuestionActions({
        action: 'edit',
        groupId,
        origin: 'add_question_scr',
        questionId: question.id,
      } as any),
    );
  }
};
