import React from 'react';

export const Reposition: React.FC = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <g>
          <g>
            <path
              d="M12.012 5l1.993 3h-1.528v3.5h3.533v-1.492l2.99 2-2.99 2V12.5h-3.533V16h1.528l-1.993 3-1.993-3h1.461v-3.5H8.03v1.504l-2.989-2 2.99-2L8.03 11.5h3.45V8h-1.46l1.992-3z"
              transform="translate(-610.000000, -198.000000) translate(600.000000, 193.000000) translate(10.000000, 5.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
