import React, { useState } from 'react';

import { Container } from 'wui/Container';

import { ApplicationBreadcrumbs } from '../../ApplicationBreadcrumbs';
import { CoverImage } from '../../CoverImage';
import { GroupDetails } from '../../GroupDetails';
import { Navigation } from '../../Navigation';

import { st, classes } from './LargeImageLayout.st.css';

interface ILargeImageLayoutProps {
  groupId: string;
}

export function LargeImageLayout(props: ILargeImageLayoutProps) {
  const { groupId } = props;

  return (
    <header className={classes.root}>
      <Container>
        <ApplicationBreadcrumbs />
      </Container>
      <Container fluid>
        <CoverImage groupId={groupId} />
      </Container>
      <Container>
        <div className={classes.container}>
          <GroupDetails groupId={groupId} />
        </div>
      </Container>
      <Container>
        <Navigation />
      </Container>
    </header>
  );
}
