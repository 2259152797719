import React from 'react';

import { Container } from 'wui/Container';

import { ApplicationBreadcrumbs } from '../../ApplicationBreadcrumbs';
import { CoverImage } from '../../CoverImage';
import { GroupDetails } from '../../GroupDetails';
import { Navigation } from '../../Navigation';

import { classes } from './ThumbnailImageLayout.st.css';

interface IThumbnailImageLayoutProps {
  groupId: string;
  withImage: boolean;
}

export function ThumbnailImageLayout(props: IThumbnailImageLayoutProps) {
  const { groupId, withImage } = props;

  return (
    <header className={classes.root}>
      <Container>
        <ApplicationBreadcrumbs />
      </Container>

      <Container>
        <div className={classes.container}>
          {withImage && <CoverImage groupId={groupId} />}

          <GroupDetails groupId={groupId} />
        </div>
      </Container>

      <Container fluid>
        <Navigation />
      </Container>
    </header>
  );
}

ThumbnailImageLayout.displayName = 'ThumbnailImageLayout';
ThumbnailImageLayout.defaultProps = {
  withImage: true,
};
