import React from 'react';
import type { ButtonProps, TextButtonProps } from 'wix-ui-tpa';

export interface IGroupSettingsContext {
  title: string;
  submitButtonProps: ButtonProps &
    TextButtonProps & {
      loading?: boolean;
    };

  set(options: Partial<Omit<IGroupSettingsContext, 'set'>>): void;
}

export const GroupSettingsContext = React.createContext(
  {} as IGroupSettingsContext,
);
