import React from 'react';

import { Container } from 'wui/Container';

import { ApplicationBreadcrumbs } from '../../ApplicationBreadcrumbs';
import { CoverImage } from '../../CoverImage';
import { GroupDetails } from '../../GroupDetails';
import { Navigation } from '../../Navigation';

import { MobileControls } from './MobileControls';

import { st, classes } from './MobileLayout.st.css';

interface IMobileLayoutProps {
  groupId: string;

  withImage?: boolean;
}

export function MobileLayout(props: IMobileLayoutProps) {
  const { groupId, withImage } = props;

  return (
    <header className={classes.root}>
      <Container fluid={!withImage}>
        {withImage ? (
          <ApplicationBreadcrumbs />
        ) : (
          <MobileControls withBackButton groupId={groupId} />
        )}
      </Container>

      {withImage && (
        <Container fluid className={classes.imageContainer}>
          <CoverImage groupId={groupId} />
          <div className={classes.overlay}>
            <Container fluid>
              <MobileControls groupId={groupId} />
            </Container>
          </div>
        </Container>
      )}

      <Container>
        <div className={classes.container}>
          <GroupDetails groupId={groupId} />
        </div>
      </Container>

      <Container fluid>
        <Navigation />
      </Container>
    </header>
  );
}
