import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { Tabs, TabsVariant, TabsSkin } from 'wix-ui-tpa';

import { SideDrawer } from 'common/components/SideDrawer';

import { GROUP_ACTIONS_SIDE_PANEL } from '../dataHooks';

import { ApplicationsTab, DetailsTab, ModerateTab } from './Tabs';
import {
  IGroupSettingsContext,
  GroupSettingsContext,
} from './GroupSettingsContext';

import { classes } from './GroupSettings.st.css';

interface IGroupSettingsProps {
  isOpen: boolean;
  groupId: string;

  onClose(): void;
}

export function GroupSettings(props: IGroupSettingsProps) {
  const { groupId, isOpen, onClose } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const [context, setContext] = React.useState({
    title: t('groups-web.group.settings'),
    submitButtonProps: {},
  } as IGroupSettingsContext);

  return (
    <GroupSettingsContext.Provider
      value={{
        ...context,
        set: (value) => setContext((context) => ({ ...context, ...value })),
      }}
    >
      <SideDrawer
        data-hook={GROUP_ACTIONS_SIDE_PANEL}
        isOpen={isOpen}
        onClose={handleClose}
        title={context.title}
        cancelButtonProps={{
          onClick: handleClose,
          label: t('groups-web.cancel'),
        }}
        submitButtonProps={{
          label: t('groups-web.save'),
          ...context.submitButtonProps,
        }}
      >
        <div className={classes.root}>
          <Tabs
            className={classes.tabs}
            variant={TabsVariant.fullWidth}
            activeTabIndex={activeTabIndex}
            onTabClick={setActiveTabIndex}
            skin={isMobile ? TabsSkin.clear : TabsSkin.fullUnderline}
            items={[
              {
                id: 'details',
                title: t('groups-web.group.settings.details'),
              },
              {
                id: 'apps',
                title: t('groups-web.group.settings.tabs'),
              },
              {
                id: 'settings',
                title: t('groups-web.group.settings.moderate'),
              },
            ]}
          />
          <SwitchTransition>
            <CSSTransition
              timeout={300}
              key={activeTabIndex}
              classNames={classes as CSSTransitionClassNames}
            >
              <div className={classes.content}>
                {activeTabIndex === 0 && (
                  <DetailsTab groupId={groupId} onClose={handleClose} />
                )}
                {activeTabIndex === 1 && (
                  <ApplicationsTab groupId={groupId} onClose={handleClose} />
                )}
                {activeTabIndex === 2 && <ModerateTab groupId={groupId} />}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </SideDrawer>
    </GroupSettingsContext.Provider>
  );

  function handleClose() {
    setActiveTabIndex(0);
    onClose();
  }
}

GroupSettings.displayName = 'GroupSettings';
