import React from 'react';
import { Text, TextTypography, ButtonPriority } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { UISref } from 'common/router';
import { BlockAlign, BlockFlow, Block } from 'common/components/Block';
import { Button } from 'common/components/Button';

import { GROUP_NOT_FOUND_DATA_HOOK } from './dataHooks';

import { classes } from './GroupErrorStates.st.css';

export function GroupNotFound() {
  const { t } = useTranslation();

  return (
    <Block
      flow={BlockFlow.row}
      place={BlockAlign.center}
      align={BlockAlign.center}
      className={classes.root}
      data-hook={GROUP_NOT_FOUND_DATA_HOOK}
    >
      <Text tagName="h2" typography={TextTypography.largeTitle}>
        {t('groups-web.groupNotFound.title')}
      </Text>
      <Text>{t('groups-web.groupNotFound.caption')}</Text>
      <Block place={BlockAlign.center}>
        <UISref state="groups">
          <Button as="a" priority={ButtonPriority.primary}>
            {t('groups-web.groupNotFound.action')}
          </Button>
        </UISref>
      </Block>
    </Block>
  );
}

GroupNotFound.displayName = 'GroupNotFound';
