import React from 'react';
import { ModalProps } from 'common/components/Modal/Modal';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Dialog } from 'common/components/Dialog/Dialog';

interface DeleteDialogProps extends ModalProps {
  groupTitle: string;

  onDelete(): void;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = (props) => {
  const { onDelete, groupTitle, ...rest } = props;
  const { t } = useTranslation();
  const title = t('groups-web.group.actions.delete.group');
  const text = t('groups-web.group.actions.delete.confirm', {
    groupName: groupTitle,
  });
  const cancel = t('groups-web.cancel');
  const confirm = t('groups-web.group.actions.delete');
  return (
    <Dialog
      title={title}
      caption={text}
      okLabel={confirm}
      cancelLabel={cancel}
      onOkClick={onDelete}
      {...rest}
    />
  );
};

DeleteDialog.displayName = 'DeleteDialog';
